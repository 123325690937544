.fbgBlue {
  background-color: #292a3c;
  color: white;
}

.flogo {
  max-width: 50%;
  margin: 0 0 2rem 0;
  cursor: pointer;
}

.fheading {
  color: #9cbdde;
  margin: 0 0 2rem 0;
}

.flinks {
  font-size: 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
}

.h-8 {
  font-size: 1.5rem;
}

.copyright-text {
  font-size:  12px;

}