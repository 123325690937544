.admin-header {
    background-color: #292a3c;
    color: white;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    position: sticky;
    top: 0;
}

.admin-header-dropdown {
    background-color: #9cbdde;
    border: none;
    color: #292a3c;

}