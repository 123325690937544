.s2border {
  border-bottom: 3px solid #9cbdde;
}

.s2options {
  cursor: pointer;
}

.s2button {
  /* background-color: #292a3c; */
  background-color: #0d6efd;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.courseImg {
  max-width: 80%;
  object-fit: contain;
}

.dropdown-item:hover {
  background-color: #a7caee;
}