.lightBlueBg {
  background-color: #9cbdde;
}

.s2hr {
  border-top: 4px dotted black;
  background-color: #9cbdde;
}

.s2p {
  font-weight: 500;
}

.black-text-list {
  line-height: 2em;
  text-align: justify;
}