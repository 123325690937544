.hbgBlue {
  background-color: #292a3c;
}

.hlogo {
  max-width: 15%;
  cursor: pointer;
}

.active {
  border-bottom: 2px solid white;
}
