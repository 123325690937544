@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;500&family=Open+Sans:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: 'Open Sans', serif;
  font-weight: 700;
}

h5,
h6 {
  font-family: "Open Sans";
}

div,
p,
span,
a {
  font-family: 'Open Sans', serif;
  font-weight: 300;
}

.secondary {
  font-family: 'Open Sans', serif;
}

.whiteText {
  color: white;
}

.blackText {
  color: #000;
}
