.blueBg {
  background-color: #292a3c;
}

.s4heading {
  color: #9cbdde;
  font-family: 'Open Sans';
}

.s4q1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 100px;
  width: 100px;
  font-family: 'Open Sans';
}

.s4q2 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 100px;
  width: 100px;
}

.s4Text {
  font-family: 'Open Sans';
}