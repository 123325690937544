.admin-students-wrapper {
    width: 100%;
    padding: 1em 2em;
}

.admin-title-text {
    font-size: 20px;
    font-weight: bold;
    color: #292a3c;
    border-bottom: 4px solid #9cbdde;
    padding: 0 0 4px
}

.student-items-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 1em 0;
}

.student-item {
    /* border: 1px solid grey; */
    border-radius: 5px;
    width: 100%;
    margin: .5em 0;
    padding: 1em;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(179,179,179,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(179,179,179,1);
    box-shadow: 0px 0px 10px 0px rgba(179,179,179,1);
}

.student-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.student-item-row span {
    width: 50%;
}
/* 
.student-item-row span:first-child {
    border-right: .5px solid lightgrey;    
} */