.s5img {
  max-height: 70vh;
  max-width: 100%;
}

.s5inputs {
  background-color: rgba(196, 196, 196, 0.568);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;
  min-width: 70%;
}

.s5textArea {
  background-color: rgba(196, 196, 196, 0.568);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;
  min-width: 70%;
}

.s5button {
  /* background-color: #292a3c !important; */
  background-color: #0d6efd !important;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  margin: 1rem 0 0 0;
}

.failed {
  color: red;
}

.success {
  color: green;
}

.cursor-pointer {
  cursor: pointer;
}

.s5dropdown {
  background-color: #292a3c !important;
  color: white !important;
}

.s5dropdown:hover {
  color: white;
}

.dropdown-item:hover {
  background-color: #a7caee;
}