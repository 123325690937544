.s3img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.s3heading {
  color: #9cbdde;
}

.s3hr {
  border-color: #9cbdde !important;
}

.s3bullet {
  height: 1rem;
  width: 1rem;
  margin: 0.4rem 0 0 0;
}

.s3button {
  /* background-color: #292a3c;#0d6efd */
  background-color: #0d6efd;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border: none;
}
