@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;500&family=Open+Sans:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: 'Open Sans', serif;
  font-weight: 700;
}

h5,
h6 {
  font-family: "Open Sans";
}

div,
p,
span,
a {
  font-family: 'Open Sans', serif;
  font-weight: 300;
}

.secondary {
  font-family: 'Open Sans', serif;
}

.whiteText {
  color: white;
}

.blackText {
  color: #000;
}

.fbgBlue {
  background-color: #292a3c;
  color: white;
}

.flogo {
  max-width: 50%;
  margin: 0 0 2rem 0;
  cursor: pointer;
}

.fheading {
  color: #9cbdde;
  margin: 0 0 2rem 0;
}

.flinks {
  font-size: 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
}

.h-8 {
  font-size: 1.5rem;
}

.copyright-text {
  font-size:  12px;

}
.hbgBlue {
  background-color: #292a3c;
}

.hlogo {
  max-width: 15%;
  cursor: pointer;
}

.active {
  border-bottom: 2px solid white;
}

.admin-header {
    background-color: #292a3c;
    color: white;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.admin-header-dropdown {
    background-color: #9cbdde;
    border: none;
    color: #292a3c;

}
.heading {
  color: #9cbdde;
}

.homeS1 {
  color: white;
  margin: -1px 0 0 0;
  min-height: 80vh;
}

.S1tablet {
  min-height: 50vh;
}

.S1button {
  background-color: #9cbdde;
  color: #292a3c;
  font-weight: 700;
  border: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.blueBg {
  background-color: #292a3c;
}

.s1image {
  max-width: 80%;
  max-height: 100%;
  object-fit: contain;
}

.lightBlueBg {
  background-color: #9cbdde;
}

.s2hr {
  border-top: 4px dotted black;
  background-color: #9cbdde;
}

.s2p {
  font-weight: 500;
}

.black-text-list {
  line-height: 2em;
  text-align: justify;
}
.s3img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.s3heading {
  color: #9cbdde;
}

.s3hr {
  border-color: #9cbdde !important;
}

.s3bullet {
  height: 1rem;
  width: 1rem;
  margin: 0.4rem 0 0 0;
}

.s3button {
  /* background-color: #292a3c;#0d6efd */
  background-color: #0d6efd;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border: none;
}

.blueBg {
  background-color: #292a3c;
}

.s4heading {
  color: #9cbdde;
  font-family: 'Open Sans';
}

.s4q1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 100px;
  width: 100px;
  font-family: 'Open Sans';
}

.s4q2 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 100px;
  width: 100px;
}

.s4Text {
  font-family: 'Open Sans';
}
.s5img {
  max-height: 70vh;
  max-width: 100%;
}

.s5inputs {
  background-color: rgba(196, 196, 196, 0.568);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;
  min-width: 70%;
}

.s5textArea {
  background-color: rgba(196, 196, 196, 0.568);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;
  min-width: 70%;
}

.s5button {
  /* background-color: #292a3c !important; */
  background-color: #0d6efd !important;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  margin: 1rem 0 0 0;
}

.failed {
  color: red;
}

.success {
  color: green;
}

.cursor-pointer {
  cursor: pointer;
}

.s5dropdown {
  background-color: #292a3c !important;
  color: white !important;
}

.s5dropdown:hover {
  color: white;
}

.dropdown-item:hover {
  background-color: #a7caee;
}
.blueBg {
  background-color: #292a3c;
}

.s4heading {
  color: #9cbdde;
  font-family: 'Open Sans';
}

.s4q1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 100px;
  width: 100px;
  font-family: 'Open Sans';
}

.s4q2 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 100px;
  width: 100px;
}

.s4Text {
  font-family: 'Open Sans';
}

.block {
  background-color: #9cbdde;
  padding: 2em 1.5em;
  border-radius: 15px;
  margin: 1em 0;
}

.block-text {
  color: white !important;
  font-size: 1.15rem;
}
.courses-block-section {
  /* background-color: #9cbdde; */
}

.courses-block-section .s4heading {
  color: #292a3c ;
  font-family: 'Open Sans';
}

.s4q1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 100px;
  width: 100px;
  font-family: 'Open Sans';
}

.s4q2 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 100px;
  width: 100px;
}

.s4Text {
  font-family: 'Open Sans';
}

.courses-block-section .block {
  background-color: #292a3c;
  padding: 2em 1.5em;
  border-radius: 15px;
  margin: 1em 0;
}

.courses-block-section .courses-block-text {
  color: #9cbdde;
  font-size: 1.15rem;
}
.blueBg {
  background-color: #292a3c;
}

.s4heading {
  color: #9cbdde;
  font-family: 'Open Sans';
}

.s4q1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 100px;
  width: 100px;
  font-family: 'Open Sans';
}

.s4q2 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 100px;
  width: 100px;
}

.s4Text {
  font-family: 'Open Sans';
}
.s1heading {
  color: #9cbdde;
}

/* color: #292a3c; */

.s2border {
  border-bottom: 3px solid #9cbdde;
}

.s2options {
  cursor: pointer;
}

.s2button {
  /* background-color: #292a3c; */
  background-color: #0d6efd;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.courseImg {
  max-width: 80%;
  object-fit: contain;
}

.dropdown-item:hover {
  background-color: #a7caee;
}
.lightBlueBg {
  background-color: #9cbdde;
}

.s2hr {
  border-top: 4px dotted black;
  background-color: #9cbdde;
}

.s2p {
  font-weight: 500;
}

.black-text-list {
  line-height: 2em;
  text-align: justify;
}
.admin-login-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
}

.admin-login-block {
    width: 350px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 1em 2em;
}

.admin-login-block .login-text {
    font-size: 20px;
    font-weight: bold;
}

.login-form {

    width: 100%;

}

.login-input, .login-input:active, .login-input:focus {
    background-color: rgba(196, 196, 196, 0.568);
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: none;
    min-width: 100%;
    outline: none;
}

.login-error-text {
    color: red;
}

.login-btn, .login-btn:active, .login-btn:focus {
    background-color: #292a3c;
    padding: 0.5rem 1rem;
    font-weight: bold;
    border: none;
    color: white;
    width: 100%;
}
.admin-students-wrapper {
    width: 100%;
    padding: 1em 2em;
}

.admin-title-text {
    font-size: 20px;
    font-weight: bold;
    color: #292a3c;
    border-bottom: 4px solid #9cbdde;
    padding: 0 0 4px
}

.student-items-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 1em 0;
}

.student-item {
    /* border: 1px solid grey; */
    border-radius: 5px;
    width: 100%;
    margin: .5em 0;
    padding: 1em;
    box-shadow: 0px 0px 10px 0px rgba(179,179,179,1);
}

.student-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.student-item-row span {
    width: 50%;
}
/* 
.student-item-row span:first-child {
    border-right: .5px solid lightgrey;    
} */
