.courses-block-section {
  /* background-color: #9cbdde; */
}

.courses-block-section .s4heading {
  color: #292a3c ;
  font-family: 'Open Sans';
}

.s4q1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 100px;
  width: 100px;
  font-family: 'Open Sans';
}

.s4q2 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 100px;
  width: 100px;
}

.s4Text {
  font-family: 'Open Sans';
}

.courses-block-section .block {
  background-color: #292a3c;
  padding: 2em 1.5em;
  border-radius: 15px;
  margin: 1em 0;
}

.courses-block-section .courses-block-text {
  color: #9cbdde;
  font-size: 1.15rem;
}