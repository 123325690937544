.heading {
  color: #9cbdde;
}

.homeS1 {
  color: white;
  margin: -1px 0 0 0;
  min-height: 80vh;
}

.S1tablet {
  min-height: 50vh;
}

.S1button {
  background-color: #9cbdde;
  color: #292a3c;
  font-weight: 700;
  border: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.blueBg {
  background-color: #292a3c;
}

.s1image {
  max-width: 80%;
  max-height: 100%;
  object-fit: contain;
}
