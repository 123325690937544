.admin-login-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
}

.admin-login-block {
    width: 350px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 1em 2em;
}

.admin-login-block .login-text {
    font-size: 20px;
    font-weight: bold;
}

.login-form {

    width: 100%;

}

.login-input, .login-input:active, .login-input:focus {
    background-color: rgba(196, 196, 196, 0.568);
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: none;
    min-width: 100%;
    outline: none;
}

.login-error-text {
    color: red;
}

.login-btn, .login-btn:active, .login-btn:focus {
    background-color: #292a3c;
    padding: 0.5rem 1rem;
    font-weight: bold;
    border: none;
    color: white;
    width: 100%;
}